import React, { Component } from "react";
import styled from "styled-components";
import Theme from "./Theme.js";
import { Clickable } from "./";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTimes } from "@fortawesome/free-solid-svg-icons";

const DropdownSelect = styled.select`
  box-shadow: ${props => (props.shadow ? `0px 0px 1vw ${Theme.BLUE}` : "none")};
  border: none;
  background: none;
  position: absolute;
  flex: none;
  outline: none;
  color: ${props => props.textColor};
  font-family: ${Theme.MAIN_FONT};
  font-weight: bold;
  font-size: 0;
  width: 100%;
  height: 2.5vh;
  z-index: 100;
  padding: 0 1% 0 1%;
  ::placeholder {
    color: ${props => props.hintColor};
  }
`;

const SelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: auto;
`;

const SelectedList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: none;
  flex: 1;
  margin: auto;
  width: 100%;
  height: 2vh;
`;

const Selected = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
  border: 2px solid grey;
  z-index: 111;
  margin: 0 1% 0 0;
  border-radius: 5px;
`;

const Option = styled.option`
  font-size: ${props => (props.fontSize ? props.fontSize : "1.2vh")};
  font-weight: bold;
  width: auto;
  color: black;
`;

const Label = styled.div`
  position: ${props => props.position};
  width: ${props => props.width || "auto"};
  font-family: ${Theme.MAIN_FONT};
  font-size: ${props => (props.fontSize ? props.fontSize : "auto")};
  font-weight: bold;
  display: ${props => (props.hidden ? "none" : "default")};
  margin: 1% auto 1% 1%;
  margin: ${props => (props.margin ? props.margin : null)};
  color: ${props => (props.color ? props.color : "black")};
`;

const ErrorText = styled.div`
  color: red;
  font-size: 80%;
  margin-left: 1%;
`;

export default class MultiSelect extends Component {
  selectInput = () => {
    const {
      onChange,
      error,
      name,
      placeholder,
      multiline,
      fontSize,
      textColor,
      type,
      onKeyUp,
      width,
      height,
      values,
      onRemove,
      options
    } = this.props;
    return (
      <SelectContainer>
        <SelectedList>
          {values &&
            values.map((value, index) => (
              <Selected>
                {value}
                <Clickable
                  onClick={() => onRemove(index)}
                  margin="0 1% auto 20%"
                >
                  <FontAwesomeIcon
                    style={{
                      color: "red",
                      height: "40%"
                    }}
                    icon={faTimes}
                  />
                </Clickable>
              </Selected>
            ))}
        </SelectedList>
        <DropdownSelect
          {...this.props}
          error={!!error}
          placeholder={placeholder}
          onKeyUp={onKeyUp}
          value=""
          id="select"
        >
          {options &&
            options.map((option, index) => (
              <Option
                key={`input${index}`}
                fontSize={fontSize}
                value={option.value}
              >
                {option.label}
              </Option>
            ))}
        </DropdownSelect>
      </SelectContainer>
    );
  };

  render() {
    const {
      onChange,
      error,
      name,
      placeholder,
      containerStyle,
      multiline,
      label,
      fontSize,
      labelSize,
      labelColor,
      textColor,
      backgroundColor,
      type,
      onKeyUp,
      errorColor,
      borderColor,
      borderRadius,
      border,
      margin,
      width,
      height,
      value,
      icon,
      options
    } = this.props;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: width || "100%",
          height: height || "auto",
          margin: margin || "auto"
        }}
      >
        <Label
          fontSize={fontSize}
          labelColor={labelColor}
          labelSize={labelSize}
        >
          {label}
        </Label>
        <div
          style={{
            border: border || "1px solid",
            borderColor: error
              ? errorColor || Theme.RED
              : borderColor || Theme.INPUT_OUTLINE_COLOR,
            borderRadius: borderRadius || "5px",
            backgroundColor: backgroundColor || Theme.INPUT_COLOR,
            flexDirection: "row",
            width: "100%",
            height: "auto",
            minHeight: "2.5vh"
          }}
        >
          {icon && (
            <FontAwesomeIcon
              icon={icon}
              style={{
                margin: "auto auto auto 1%",
                width: fontSize + ".5" || "1.3vw",
                height: fontSize + ".5" || "1.3vw",
                color: "grey"
              }}
            />
          )}
          {this.selectInput()}
        </div>
        {!!error && <ErrorText>{error || "error"}</ErrorText>}
      </div>
    );
  }
}
