import React, { Component } from "react";
import styled from "styled-components";
import Theme from "./Theme.js";
import { Row, Column, Text, Input, Clickable } from "./";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTimes } from "@fortawesome/free-solid-svg-icons";

export default class MultiSelectList extends Component {
  render() {
    const { label, options, width, selected, onSelect, onRemove } = this.props;
    return (
      <Column width={width}>
        <Input
          name="form"
          label={label}
          options={[{ label: "Select an Option To Add" }].concat(options)}
          value={0}
          margin="auto 1% 1% auto"
          onChange={onSelect}
        />
        <div
          style={{
            backgroundColor: "white",
            borderRadius: "5px",
            border: "2px solid black",
            width: "95%",
            minHeight: "30px",
            margin: "0 auto 0 auto",
            padding: "1%"
          }}
        >
          {selected &&
            selected.map((selection, selectIndex) => (
              <Row>
                <Text>{selection.label || selection}</Text>
                <Clickable
                  margin="auto 0 auto auto"
                  onClick={() => onRemove(selectIndex, selection)}
                >
                  <FontAwesomeIcon
                    icon={faTimes}
                    style={{
                      height: "2vh",
                      width: "auto",
                      color: "red"
                    }}
                  />
                </Clickable>
              </Row>
            ))}
        </div>
      </Column>
    );
  }
}
