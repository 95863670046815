import React from "react";
import { Home } from "./pages";
import Contact from "./pages/home/contactPage.js";
import cookie from "js-cookie";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/home" component={Home} />
        <Route path="/contact" component={Contact} />
        <Redirect to="/home" />
      </Switch>
    </Router>
  );
}

export default App;
