import React, { Component } from "react";
import styled, { keyframes, css } from "styled-components";
import { Row } from "./";
import Theme from "./Theme.js";

const Tab = styled.button`
  outline: none;
  border: none;
  background-color: ${props =>
    props.selected ? Theme.HIGHLIGHT_COLOR : Theme.MAIN_COLOR};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 5vh;
  width: auto;
  font-size: 1vw;
  border: 2px solid ${Theme.ACCENT_COLOR};
  padding: 0.5vh 1.5% 0% 1.5%;
  cursor: pointer;
  color: ${Theme.WHITE};
  box-shadow: 0px 0px 1vh black;
  margin-right: 0.3vw;
  margin-bottom: -1px;
  &:hover {
    background-color: ${Theme.HIGHLIGHT_COLOR};
  }
`;
export default class Tabs extends Component {
  render() {
    const { tabs = [], selected = 0 } = this.props;
    return (
      <div>
        <Row borderBottom={`1vh solid ${Theme.ACCENT_COLOR}`}>
          {tabs.map((tab, index) => (
            <Tab selected={selected === index} onClick={tab.onClick}>
              {tab.label}
            </Tab>
          ))}
        </Row>
        {this.props.children}
      </div>
    );
  }
}
