import React, { Component } from "react";
import styled from "styled-components";
import Theme from "./Theme.js";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TextInput = styled.input`
  box-shadow: ${props => (props.shadow ? `0px 0px 1vw ${Theme.BLUE}` : "none")};
  color: ${props => props.textColor};
  font-family: ${Theme.MAIN_FONT};
  font-weight: bold;
  font-size: ${props => props.fontSize || "100%"};
  width: ${props => props.width || "100%"};
  height: 100%;
  background: none;
  border: none;
  padding: 1% 2% 1% 2%;
  outline: none;
  ::placeholder {
    color: ${props => props.hintColor};
  }
`;

const DropdownSelect = styled.select`
  box-shadow: ${props => (props.shadow ? `0px 0px 1vw ${Theme.BLUE}` : "none")};
  border: none;
  background: none;
  color: ${props => props.textColor};
  font-family: ${Theme.MAIN_FONT};
  font-weight: bold;
  font-size: ${props => props.fontSize || "1.2vw"};
  width: ${props => props.width || "100%"};
  height: ${props => props.height || "auto"};
  padding: 2% 2% 2% 2%;
  ::placeholder {
    color: ${props => props.hintColor};
  }
`;

const Option = styled.option`
  font-size: ${props => (props.fontSize ? props.fontSize : "1vw")};
  font-weight: bold;
`;

const Label = styled.div`
  position: ${props => props.position};
  width: ${props => props.width || "auto"};
  font-family: ${Theme.MAIN_FONT};
  font-size: ${props => (props.fontSize ? props.fontSize : "auto")};
  font-weight: bold;
  display: ${props => (props.hidden ? "none" : "default")};
  margin: 1% auto 1% 1%;
  margin: ${props => (props.margin ? props.margin : null)};
  color: ${props => (props.color ? props.color : "black")};
`;

const ErrorText = styled.div`
  color: red;
  font-size: 100%;
  margin-left: 1%;
`;

export default class Input extends Component {
  state = {
    month: "",
    day: "",

    year: ""
  };

  handleDate = () => {
    const { month, day, year } = this.state;
    const date = moment(month + "-" + day + "-" + year);
    this.props.onChange(date);
  };
  render() {
    const {
      onChange,
      error,
      name,
      placeholder,
      containerStyle,
      multiline,
      label,
      fontSize,
      labelSize,
      labelColor,
      textColor,
      backgroundColor,
      type,
      onKeyUp,
      errorColor,
      borderColor,
      borderRadius,
      border,
      margin,
      width,
      height,
      value,
      icon,
      options
    } = this.props;
    const { month, day, year } = this.state;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: width || "100%",
          height: height || "auto",
          margin: margin || "auto"
        }}
      >
        <Label labelColor={labelColor} labelSize={labelSize}>
          {label}
        </Label>
        <div
          style={{
            display: "flex",

            border: border || "1px solid",
            fontSize: fontSize || "100%",
            borderColor: error
              ? errorColor || Theme.RED
              : borderColor || Theme.INPUT_OUTLINE_COLOR,
            borderRadius: borderRadius || "5px",
            backgroundColor: backgroundColor || Theme.INPUT_COLOR,
            flexDirection: "row",
            width: "100%",
            height: "100%"
          }}
        >
          {icon && (
            <FontAwesomeIcon
              icon={icon}
              style={{
                margin: "auto auto auto 1%",
                width: fontSize + ".5" || "1.3vw",
                height: fontSize + ".5" || "1.3vw",
                color: "grey"
              }}
            />
          )}
          <TextInput
            {...this.props}
            width={"15%"}
            onChange={e =>
              this.setState({ month: e.target.value }, this.handleDate)
            }
            value={month}
            error={!!error}
            placeholder={"MM"}
            onKeyUp={onKeyUp}
          />
          /
          <TextInput
            {...this.props}
            width={"15%"}
            onChange={e =>
              this.setState({ day: e.target.value }, this.handleDate)
            }
            value={day}
            error={!!error}
            placeholder={"DD"}
            onKeyUp={onKeyUp}
          />
          /
          <TextInput
            {...this.props}
            width={"40%"}
            onChange={e =>
              this.setState({ year: e.target.value }, this.handleDate)
            }
            value={year}
            error={!!error}
            placeholder={"YYYY"}
            onKeyUp={onKeyUp}
          />
          {!!error && <ErrorText>{error || "error"}</ErrorText>}
        </div>
      </div>
    );
  }
}
