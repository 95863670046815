import React, { Component } from "react";
import styled from "styled-components";
import Theme from "./Theme.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TextInput = styled.input`
  box-shadow: ${(props) =>
    props.shadow ? `0px 0px 1vw ${Theme.BLUE}` : "none"};
  color: ${(props) => props.textColor};
  font-family: ${Theme.MAIN_FONT};
  font-weight: bold;
  font-size: ${(props) => props.fontSize || "100%"};
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  outline: none;
  ::placeholder {
    color: ${(props) => props.hintColor};
  }
`;
const DateTime = styled.input`
  box-shadow: ${(props) =>
    props.shadow ? `0px 0px 1vw ${Theme.BLUE}` : "none"};
  color: ${(props) => props.textColor};
  font-family: ${Theme.MAIN_FONT};
  font-weight: bold;
  font-size: ${(props) => props.fontSize || "100%"};
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  padding: 0;
  outline: none;
  ::placeholder {
    color: ${(props) => props.hintColor};
  }
`;

const DropdownSelect = styled.select`
  box-shadow: ${(props) =>
    props.shadow ? `0px 0px 1vw ${Theme.BLUE}` : "none"};
  border: none;
  background: none;
  outline: none;
  color: ${(props) => props.textColor};
  font-family: ${Theme.MAIN_FONT};
  font-weight: bold;
  font-size: ${(props) => props.fontSize || "100%"};
  width: 100%;
  height: 100%;
  padding: 0 1% 0 1%;
  ::placeholder {
    color: ${(props) => props.hintColor};
  }
`;

const Option = styled.option`
  font-size: ${(props) => (props.fontSize ? props.fontSize : "100%")};
  font-weight: bold;
`;

const MultilineInput = styled.textarea`
  box-shadow: ${(props) =>
    props.shadow ? `0px 0px 1vw ${Theme.BLUE}` : "none"};
  border: none;
  background: none;
  outline: none;
  height: 100%;
  color: ${(props) => props.textColor};
  font-family: ${Theme.MAIN_FONT};
  font-weight: bold;
  font-size: ${(props) => props.fontSize || "100%"};
  width: 100%;
  background-color: ${(props) => props.backgroundColor};

  padding: 2px 1% 2px 1%;
  box-sizing: border-box;
  ::placeholder {
    color: ${(props) => props.hintColor};
  }
  ::-webkit-scrollbar-track {
    background-color: "grey";
  }
  ::-webkit-scrollbar-thrumb {
    border-radius: 12%;
  }
  ::-webkit-scrollbar {
    display: none;
  }
`;

const Label = styled.div`
  position: ${(props) => props.position};
  width: ${(props) => props.width || "auto"};
  font-family: ${Theme.MAIN_FONT};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "auto")};
  font-weight: bold;
  display: ${(props) => (props.hidden ? "none" : "default")};
  margin: 1% auto 1% 1%;
  color: ${(props) => (props.color ? props.color : "black")};
`;

const ErrorText = styled.div`
  color: red;
  font-size: 80%;
  margin-left: 1%;
`;

export default class Input extends Component {
  selectInput = () => {
    const {
      onChange,
      error,
      name,
      placeholder,
      multiline,
      fontSize,
      textColor,
      type,
      onKeyUp,
      width,
      height,
      value,
      options,
    } = this.props;
    if (options)
      return (
        <DropdownSelect
          {...this.props}
          error={!!error}
          placeholder={placeholder}
          onKeyUp={onKeyUp}
        >
          {options.map((option, index) => (
            <Option
              key={`input${index}`}
              fontSize={fontSize}
              value={option.value || index}
            >
              {option.label}
            </Option>
          ))}
        </DropdownSelect>
      );
    else if (multiline)
      return (
        <MultilineInput
          {...this.props}
          onChange={(e) => onChange(e.target.value, e)}
          error={!!error}
          value={value || ""}
          multiline
          placeholder={placeholder}
          onKeyUp={onKeyUp}
        />
      );
    else if (type === "date" || type === "time")
      return (
        <DateTime
          {...this.props}
          value={value || ""}
          onChange={(e) => onChange(e.target.value, e)}
          error={!!error}
          placeholder={placeholder}
          onKeyUp={onKeyUp}
        />
      );
    else
      return (
        <TextInput
          {...this.props}
          value={value || ""}
          onChange={(e) => onChange(e.target.value, e)}
          error={!!error}
          placeholder={placeholder}
          onKeyUp={onKeyUp}
        />
      );
  };

  render() {
    const {
      onChange,
      error,
      name,
      placeholder,
      containerStyle,
      multiline,
      label,
      fontSize,
      labelSize,
      labelColor,
      textColor,
      backgroundColor,
      type,
      onKeyUp,
      errorColor,
      borderColor,
      borderRadius,
      border,
      margin,
      width,
      height,
      value,
      icon,
      options,
    } = this.props;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: width || "99%",
          height: height || "auto",
          margin: margin || "auto",
        }}
      >
        <Label
          fontSize={fontSize}
          labelColor={labelColor}
          labelSize={labelSize}
        >
          {label}
        </Label>
        <div
          style={{
            border: border || ".15vw solid",
            borderColor: error
              ? errorColor || Theme.RED
              : borderColor || Theme.INPUT_OUTLINE_COLOR,
            borderRadius: borderRadius || "5px",
            backgroundColor: backgroundColor || Theme.INPUT_COLOR,
            flexDirection: "row",
            width: "100%",
            height: "100%",
          }}
        >
          {icon && (
            <FontAwesomeIcon
              icon={icon}
              style={{
                margin: "auto auto auto 1%",
                width: fontSize + ".5" || "1.3vw",
                height: fontSize + ".5" || "1.3vw",
                color: "grey",
              }}
            />
          )}
          {this.selectInput()}
        </div>
        {!!error && <ErrorText>{error || "error"}</ErrorText>}
      </div>
    );
  }
}
