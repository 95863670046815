import React, { Component } from "react";
import styled from "styled-components";
import { Clickable } from "./";
import Theme from "./Theme.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faSquare } from "@fortawesome/free-solid-svg-icons";

const Label = styled.div`
  font-family: ${Theme.MAIN_FONT};
  font-size: ${props => (props.fontSize ? props.fontSize : "100%")};
  font-weight: bold;
  width: 100%;
  color: ${props => (props.color ? props.color : "black")};
  margin: auto;
`;
const CheckInput = styled.input`
  color: ${props => props.textColor};
  height: ${props => props.height || "2vh"};
  width: ${props => props.height || "2vh"};
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  ::placeholder {
    color: ${props => props.hintColor};
  }
`;
const ErrorText = styled.div`
  color: red;
  font-size: 80%;
  margin-left: 1%;
`;

export default class Checkbox extends Component {
  render() {
    const {
      onChange,
      error,
      name,
      placeholder,
      containerStyle,
      multiline,
      label,
      fontSize,
      labelSize,
      labelColor,
      textColor,
      backgroundColor,
      type,
      onKeyUp,
      errorColor,
      borderColor,
      borderRadius,
      border,
      margin,
      width,
      height,
      disabled,
      value,
      icon,
      options,
      reverse
    } = this.props;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: reverse ? "row-reverse" : "row",
          width: width || "auto",
          margin: margin || "auto 0 auto auto"
        }}
      >
        <CheckInput
          checked={value}
          onChange={() => onChange(!value)}
          type="checkbox"
          disabled={disabled}
        />
        <Clickable
          margin={reverse ? "auto 1% auto auto" : "auto auto auto 1%"}
          onClick={() => onChange(!value)}
        >
          <Label
            fontSize={fontSize}
            labelColor={labelColor}
            labelSize={labelSize}
          >
            {label}
          </Label>
        </Clickable>
      </div>
    );
  }
}
