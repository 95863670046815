import React, { Component } from "react";
import styled, { css, keyframes } from "styled-components";
import Theme from "../../globalComponents/Theme.js";
import windowSize from "react-window-size";
import axios from "axios";
import {
  Button,
  InfoBox,
  Input,
  Text,
  NavBar,
  Section,
} from "../../globalComponents";
import emailjs from "emailjs-com";
class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      name: "",
      email: "",
      message: "",
      address: "",
      nameError: null,
      emailError: null,
      messageError: null,
      addressError: null,
    };
  }

  verify = () => {
    const {
      name,
      email,
      message,
      nameError,
      emailError,
      messageError,
      addressError,
    } = this.state;
    var error = false;
    if (!name) {
      error = true;
      this.setState({ nameError: "Required" });
    } else this.setState({ nameError: "" });
    if (!email) {
      error = true;
      this.setState({ emailError: "Required" });
    } else this.setState({ emailError: "" });
    if (!email) {
      error = true;
      this.setState({ addressError: "Required" });
    } else this.setState({ addressError: "" });
    if (!message) {
      error = true;
      this.setState({ messageError: "Required" });
    } else this.setState({ messageError: "" });
    return !error;
  };

  submit = () => {
    const { name, email, message, address } = this.state;
    if (this.verify()) {
      var params = {
        name,
        email,
        message,
        address,
      };
      axios
        .post(`${process.env.REACT_APP_API_URL}/site/contact`, {
          domain: "thehoneydoboys.com",
          company: process.env.REACT_APP_COMPANY_NAME,
          emailName: "contact",
          replyName: name,
          replyEmail: email,
          message: message,
        })
        .then((response) => {
          if (response.data.success) {
            this.setState({ success: true });
          } else
            this.setState({
              general:
                "Couldn't send email,try checking your name and email or calling instead",
            });
        })
        .catch((error) =>
          this.setState({
            general:
              "Couldn't send email, try checking your name and email or calling instead",
          })
        );
    }
  };
  render() {
    const { open, onClose } = this.props;
    const {
      success,
      nameError,
      emailError,
      addressError,
      messageError,
      error,
      general,
      address,
      email,
      name,
      message,
    } = this.state;
    const mobile = this.props.windowWidth < 1100;
    return (
      <NavBar history={this.props.history}>
        <Section minHeight="100vh" backgroundColor={Theme.SECONDARY_COLOR}>
          <div
            style={{
              display: "flex",
              margin: mobile ? "5% auto " : "2% auto auto auto",
              boxShadow: "0 0 20vw solid black",
              width: mobile ? "90vw" : "50vw",
              zIndex: 99,
              flexDirection: "column",
              backgroundColor: "white",
              padding: "1%",
              borderRadius: "10px",
            }}
          >
            {success ? (
              <div>
                <Text textAlign="center">Email sent successfully! </Text>
                <Button
                  width="100%"
                  margin="10% 0 1% 0"
                  onClick={() => this.props.history.push("/home")}
                >
                  Exit
                </Button>
              </div>
            ) : (
              <div>
                <Text textAlign="center" size={mobile ? "150%" : "200%"}>
                  Request Your Quote
                </Text>
                <Input
                  label="Your Name"
                  error={nameError}
                  value={name}
                  onChange={(value) => this.setState({ name: value })}
                />
                <Input
                  label="Your Email"
                  error={emailError}
                  value={email}
                  onChange={(value) => this.setState({ email: value })}
                />
                <Input
                  label="Service Address"
                  error={addressError}
                  value={address}
                  onChange={(value) => this.setState({ address: value })}
                />
                <Input
                  multiline
                  height="30vh"
                  margin="1% auto 1% auto"
                  label="Describe your Roofing and Gutter needs"
                  error={messageError}
                  value={message}
                  onChange={(value) => this.setState({ message: value })}
                />
                <Button width="98%" margin="10% 1% 3% 1%" onClick={this.submit}>
                  <Text size={mobile ? "150%" : "200%"} color={Theme.BLACK}>
                    {" "}
                    Send
                  </Text>
                </Button>
                <Text textAlign="center"> {general}</Text>
              </div>
            )}
          </div>
        </Section>
      </NavBar>
    );
  }
}
export default windowSize(Contact);
