const theme = {
  PAGE_BACKGROUND: "#4C4C4C",
  MAIN_COLOR: "#60b851",
  SECONDARY_COLOR: "#559ddf",
  BORDER_COLOR: "#979796",
  SHADOW_COLOR: "rgba(0,0,0,1)",
  INPUT_OUTLINE_COLOR: "#979796",
  INPUT_COLOR: "rgba(255,255,255,1)",
  BUTTON_COLOR: "#60b851",
  BUTTON_HOVER_COLOR: "#559ddf",
  BORDER_COLOR: "#727272",
  NAV_TEXT_COLOR: "#2D303D",
  NAV_BAR_COLOR: "#ff8e38",
  DARK_GREY: "#535354",
  LIGHT_GREY: "#403c3c",
  LIGHTER_GREY: "#a39d9d",
  BLUE: "rgba(88,99,135, .80)", //"#586387",
  BLACK: "rgba(24,24,25,.95)",
  RED: "rgba(178, 34, 52,1)",
  WHITE: "rgba(255,255,255,.98)",
  BANNER_FONT: "arial",
  MAIN_FONT: "Helvetica",
  BUTTON_FONT: "",
  BUTTON_TEXT_COLOR: "white",
};

export default theme;
