import React, { Component } from "react";
import styled, { keyframes, css } from "styled-components";
import Theme from "./Theme.js";
import { Image, Clickable, Column } from "./";
import Default from "../assets/default.png";
import Cog from "../assets/cog.png";
import { fadeInDown, fadeOutUp, pulse } from "react-animations";
import cookie from "js-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

const Menu = styled.ul`
  display: ${(props) => (props.open ? "block" : "none")};
  flex-direction: column;
  background-color: ${Theme.BLACK};
  box-shadow: 0px 0.8vh 1vh 0.1vh ${Theme.BLUE};
  position: fixed;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  text-align: center;
  list-style: none;
  padding: 1vh 0 1vh 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  right: 0;
  top: 5%;
  width: 100%;
  z-index: 1;
  animation: ${(props) => props.animation};
  animation-delay: ${(props) => props.animationDelay};
  animation-fill-mode: both;
`;

const SubMenu = styled.ul`
  display: ${(props) => (props.open ? "block" : "none")};
  flex-direction: column;
  text-align: center;
  list-style: none;
  margin: auto;
  padding: 1vh 0 1vh 0;
  border: 2px solid ${Theme.LIGHT_GREY};
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  width: 98%;
  z-index: 1;
`;
const Option = styled.button`
  outline: none;
  border: none;
  color: ${Theme.NAV_TEXT_COLOR};
  width: 80%;
  margin: 1vh auto auto auto;
  cursor: pointer;
  font-size: ${(props) => props.size || "130%"};
  font-family: ${Theme.MAIN_FONT};
  background: none;
  &:hover {
    animation: 2.5s ${keyframes`${pulse}`} infinite;
    animation-fill-mode: forwards;
    transition: color 1s ease;
    color: ${Theme.BLUE};
  }
`;
class ProfileButton extends Component {
  state = { open: false };
  render() {
    const { img, size, margin, history, routes = [], filter } = this.props;
    const { open, menu } = this.state;
    return (
      <div style={{ margin: margin, display: "flex" }}>
        <Clickable
          onClick={() => this.setState({ open: !open, menu: null })}
          margin="auto 0 auto auto"
        >
          <FontAwesomeIcon
            style={{
              color: Theme.ORANGE,
              height: "30px",
              width: "30px",
            }}
            icon={faBars}
          />
        </Clickable>
        <Menu open={open}>
          {routes.map((option, index) => {
            if (option.super && cookie.get("superAdmin") !== "true") return;
            else if (option.owner && cookie.get("owner") !== "true") return;
            else
              return option.menu ? (
                <Column>
                  <Option onClick={() => this.setState({ menu: index })}>
                    {option.label}
                  </Option>
                  <SubMenu open={menu == index}>
                    {filter(option.menu).map((item) => (
                      <Option
                        size="100%"
                        onClick={() => this.props.history.push(item.route)}
                      >
                        {item.label}
                      </Option>
                    ))}
                  </SubMenu>
                </Column>
              ) : (
                <Option onClick={() => this.props.history.push(option.route)}>
                  {option.label}
                </Option>
              );
          })}
        </Menu>
      </div>
    );
  }
}
export default ProfileButton;
