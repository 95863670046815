import React, { Component } from "react";
import styled, { keyframes, css } from "styled-components";
import { Text } from "./";
import Theme from "./Theme.js";
import LeftArrow from "../assets/LeftArrow.svg";
import RightArrow from "../assets/RightArrow.svg";
import {
  fadeInLeft,
  fadeOutLeft,
  fadeOutRight,
  fadeInRight,
  pulse
} from "react-animations";

const Menu = styled.ul`
  display: ${props => (props.open ? "flex" : "none")};
  flex-direction: column;
  background-color: ${Theme.BLACK};
  box-shadow: 0px 0.8vh 1vh 0.1vh ${Theme.BLUE};
  margin: 0;
  list-style: none;
  padding: 1vh 0 1vh 0;
  border: 2px solid white;
  font-size: 100%;
  font: inherit;
  z-index: 99;
  animation: ${props => props.animation};
  animation-duration: 0.4s;
  animation-timing-function: linear;
`;
const Option = styled.button`
  outline: none;
  border: none;
  color: white;
  width: 80%;
  margin: 1vh auto auto auto;
  cursor: pointer;
  font-size: ${props => (props.mobile ? "1.5vh" : "2vh")};
  background: none;
  &:hover {
    animation: 2.5s ${keyframes`${pulse}`} infinite;
    animation-fill-mode: forwards;
    transition: color 1s ease;
    color: ${Theme.BLUE};
  }
`;

const PopOut = styled.button`
  outline: none;
  border: none;
  display: flex;
  margin-left: ${props => (props.right ? "auto" : null)};
  margin-right: ${props => (props.right ? null : "auto")};
  background-color: ${Theme.BLACK};
  color: white;
  padding: ${props =>
    props.right ? "1.5vh 1.5vh 1.5vh 1vh" : "1.5vh 1vh 1.5vh 1.5vh"};
  border-radius: ${props => (props.right ? "10px 0 0 10px" : "0 10px 10px 0")};
  cursor: pointer;
  animation: ${props => props.animation};
  animation-duration: 0.4s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  width: ${props => props.width};
`;
export default class Dropdown extends Component {
  state = {
    open: "initialize"
  };

  slide = (open, width, maxWidth) => {
    if (open === "initialize") return null;
    else if (open)
      return keyframes`
      from {
        width: ${width || "100%"}
      }
      to {
        width: ${maxWidth || "100vw"}
      }
    `;
    else if (!open)
      return keyframes`
      from {
        width: ${maxWidth || "100vw"}
      }
      to {
        width: ${width || "100%"}
      }
    `;
  };

  render() {
    const {
      label,
      options = [],
      containerStyle,
      mobile,
      right,
      width,
      maxWidth,
      fontSize,
      top,
      hoverable,
      order
    } = this.props;
    const { open } = this.state;
    return (
      <div
        style={
          containerStyle || {
            display: "flex",
            flexDirection: "column",
            position: "fixed",
            overflow: "visible",
            top: top || "10vh",
            right: right ? "0" : null,
            zIndex: order
          }
        }
        onMouseLeave={() =>
          open !== "initialize" && hoverable && this.setState({ open: false })
        }
      >
        <PopOut
          right={right}
          width={width}
          maxWidth={maxWidth}
          top={top}
          animation={this.slide(open, width, maxWidth)}
          onClick={() =>
            this.setState({ open: open === "initialize" || !open })
          }
          mobile={mobile}
          onMouseEnter={() =>
            setTimeout(() => hoverable && this.setState({ open: true }), 50)
          }
        >
          {right && (
            <img
              src={LeftArrow}
              style={{
                width: fontSize || "4vh",
                height: "auto",
                margin: "auto 5% auto 1%"
              }}
            />
          )}{" "}
          <Text
            size={fontSize || "4vh"}
            color="inherit"
            margin={!right ? "auto 0 auto auto" : "auto auto auto 0"}
          >
            {label}
          </Text>{" "}
          {!right && (
            <img
              src={RightArrow}
              style={{
                width: fontSize || "4vh",
                height: "auto",
                margin: "auto 1% auto 5%"
              }}
            />
          )}
        </PopOut>
        <Menu
          open={open !== "initialize" && open}
          animation={this.slide(open, "0px", maxWidth)}
        >
          {options.map((option, index) => (
            <Option key={option.label} mobile={mobile} onClick={option.onClick}>
              {option.label}
            </Option>
          ))}
        </Menu>
      </div>
    );
  }
}
