import React, { Component } from "react";
import windowSize from "react-window-size";
import styled from "styled-components";
import { Column, Text, Button } from "./index.js";
import Theme from "./Theme.js";

const Popup = styled.div`
  background-color: white;
  border-radius: 20px;
  height: 100%;
  z-index: 90;
  padding: 1%;
`;

class Notification extends Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event) {
    if (
      this.wrapperRef &&
      !this.wrapperRef.current.contains(event.target) &&
      this.props.open
    ) {
      this.props.onClose();
    }
  }
  render() {
    const { open, onClose, width } = this.props;
    const mobile = this.props.windowWidth < 700;
    return (
      <div
        style={{
          width: "100vw",
          minHeight: "100vh",
          position: "fixed",
          margin: mobile ? "1vh 2.5vw auto 2.5vw" : "1vh 10vw auto 10vw",
          zIndex: 90,
          display: open ? "flex" : "none",
          position: "fixed",
          top: "10vh",
        }}
      >
        <Popup ref={this.wrapperRef} open={open}>
          <Column
            width={mobile ? "95vw" : "80vw"}
            maxHeight="90vh"
            overflowY="auto"
          >
            {this.props.children}
          </Column>
        </Popup>
        <div
          style={{
            backgroundColor: Theme.BLACK,
            width: "100vw",
            minHeight: "100%",
            zIndex: 85,
            top: 0,
            left: 0,
            display: open ? "flex" : "none",
            position: "fixed",
          }}
        />
      </div>
    );
  }
}
export default windowSize(Notification);
