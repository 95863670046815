import React, { Component } from "react";
import styled, { css, keyframes } from "styled-components";
import Theme from "./Theme.js";
import { Loader, Row, Clickable, Column } from "./";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";

const DotSelect = styled.span`
  cursor: pointer;
  height: 20px;
  width: 20px;
  margin: -22px 1% 0 0;
  background-color: ${(props) =>
    props.selected
      ? props.hoverColor || Theme.BUTTON_HOVER_COLOR
      : Theme.MAIN_COLOR};
  border-radius: 50%;
  display: inline-block;
  box-shadow: 0px 0px 20px black;
  &:hover {
    transition: background-color 0.6s ease;
    background: ${(props) =>
      props.disabled ? null : props.hoverColor || Theme.BUTTON_HOVER_COLOR};
  }
`;

const ImageContainer = styled.div`
  border: none;
  height: ${(props) => props.height};
`;
export default class Button extends Component {
  state = {
    cur: 0,
    touched: false,
  };

  cycleImages = () => {
    const { cur, touched } = this.state;
    const { images, speed } = this.props;
    if (touched) return;
    else if (cur != images.length - 1)
      this.setState({ cur: cur + 1 }, () =>
        setTimeout(this.cycleImages, speed || 3000)
      );
    else
      this.setState({ cur: 0 }, () =>
        setTimeout(this.cycleImages, speed || 3000)
      );
  };

  componentDidMount() {
    const { cur } = this.state;
    const { freeze, images, speed } = this.props;
    if (!freeze) {
      setTimeout(this.cycleImages, speed || 3000);
    }
  }

  render() {
    const {
      onClick,
      children,
      loading,
      loaderSize,
      height,
      images,
      maxHeight,
      padding,
      border,
      width,
      background,
    } = this.props;

    const { cur } = this.state;
    return (
      <Row
        backgroundColor={background || "rgba(0, 0, 0, 0.8)"}
        margin="0"
        border={border}
        padding={padding}
        width={width}
        noWrap={true}
      >
        <Clickable
          borderRadius={"20%"}
          margin="auto auto auto 0"
          width="30px"
          height="100%"
          background="rgba(0,0,0,.3)"
          hover="rgba(0,0,0,.6)"
          onClick={() =>
            this.setState({ cur: cur != 0 ? cur - 1 : 0, touched: true })
          }
        >
          <FontAwesomeIcon
            style={{
              height: "100%",
              width: "100%",
              color: "rgba(255,255,255,.8)",
            }}
            icon={faChevronLeft}
          />
        </Clickable>
        <Column width="100%">
          <ImageContainer maxHeight={maxHeight} height={height}>
            {typeof images[cur] == "string" &&
            images[cur].includes("data:video") ? (
              <video
                loop
                id={`vid`}
                style={{
                  display: "block",
                  backgroundColor: "blue",
                  maxHeight: "100%",
                  maxWidth: "100%",
                  margin: "auto",
                }}
                autoPlay
                muted
              >
                <source src={images[cur]} type="video/mp4" />
              </video>
            ) : (
              <img
                key={`image`}
                style={{
                  display: "block",
                  backgroundColor: "blue",
                  maxHeight: "100%",
                  maxWidth: "100%",
                  margin: "auto",
                }}
                src={images[cur]}
              />
            )}
          </ImageContainer>
          <Row height="0" justify="center" margin="0 0 0 0">
            {images.map((image, index) => (
              <DotSelect
                selected={cur === index}
                onClick={() => this.setState({ cur: index, touched: true })}
              />
            ))}
          </Row>
        </Column>
        <Clickable
          borderRadius={"20%"}
          margin="auto 0 auto auto"
          width="30px"
          height="100%"
          background="rgba(0,0,0,.3)"
          hover="rgba(0,0,0,.6)"
          onClick={() =>
            this.setState({
              cur: cur != images.length - 1 ? cur + 1 : images.length - 1,
              touched: true,
            })
          }
        >
          <FontAwesomeIcon
            style={{
              width: "100%",
              height: "100%",
              color: "rgba(255,255,255,.8)",
            }}
            icon={faChevronRight}
          />
        </Clickable>
      </Row>
    );
  }
}
