import React, { Component } from "react";
import styled, { keyframes, css } from "styled-components";
import Theme from "./Theme.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fadeInDown, fadeOutUp, pulse } from "react-animations";
import { Text, Select } from "./";

const ErrorText = styled.div`
  color: red;
  font-size: 100%;
  margin-left: 1%;
`;

export default class Picker extends Component {
  state = {
    open: false
  };
  render() {
    const {
      onChange,
      error,
      name,
      placeholder,
      containerStyle,
      multiline,
      label,
      fontSize,
      labelSize,
      labelColor,
      textColor,
      backgroundColor,
      type,
      onKeyUp,
      errorColor,
      borderColor,
      borderRadius,
      border,
      margin,
      width,
      height,
      value,
      icon,
      options,
      inputProps
    } = this.props;
    const { open } = this.state;
    return <Select {...inputProps}></Select>;
  }
}
