import React, { Component } from "react";
import styled, { keyframes, css } from "styled-components";
import Theme from "./Theme.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fadeInDown, fadeOutUp, pulse } from "react-animations";
import { Text } from "./";

const TextInput = styled.input`
  box-shadow: ${props => (props.shadow ? `0px 0px 1vw ${Theme.BLUE}` : "none")};
  color: ${props => props.textColor};
  font-family: ${Theme.MAIN_FONT};
  font-weight: bold;
  font-size: ${props => props.fontSize || "100%"};
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  padding-left: 2%;
  outline: none;
  ::placeholder {
    color: ${props => props.hintColor};
  }
`;

const DropdownSelect = styled.input`
  box-shadow: ${props => (props.shadow ? `0px 0px 1vw ${Theme.BLUE}` : "none")};
  border: none;
  outline: none;
  background: none;
  padding-left: 2%;
  outline: none;
  color: ${props => props.textColor};
  font-family: ${Theme.MAIN_FONT};
  font-weight: bold;
  font-size: ${props => props.fontSize || "100%"};
  width: 100%;
  margin: auto;
  padding: 2% 2% 2% 2%;
  highlight: none;

  ::placeholder {
    color: ${props => props.hintColor};
  }
`;
const Menu = styled.ul`
  display: ${props => (props.open ? "flex" : "none")};
  flex-direction: column;
  background-color: white;
  box-shadow: 0px 0.8vh 1vh 0.1vh ${Theme.BLUE};
  position: relative;
  padding: 2% 2% 2% 2%;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  list-style: none;
  font-size: 100%;
  margin: -2px -2px auto auto;
  font: inherit;
  z-index: 1;
  border: ${props => props.border || "1px solid"};
  border-color: ${Theme.INPUT_OUTLINE_COLOR};
  animation: ${props => props.animation};
  animation-delay: ${props => props.animationDelay};
  animation-fill-mode: both;
`;
const Label = styled.div`
  position: ${props => props.position};
  width: ${props => props.width || "auto"};
  font-size: ${props => (props.fontSize ? props.fontSize : "auto")};
  font-weight: bold;
  display: ${props => (props.hidden ? "none" : "default")};
  margin: 1% auto 1% 1%;
  margin: ${props => (props.margin ? props.margin : null)};
  color: ${props => (props.color ? props.color : "black")};
`;

const ErrorText = styled.div`
  color: red;
  font-size: 100%;
  margin-left: 1%;
`;

export default class Select extends Component {
  state = {
    open: false
  };
  render() {
    const {
      onChange,
      error,
      name,
      placeholder,
      containerStyle,
      multiline,
      label,
      fontSize,
      labelSize,
      labelColor,
      textColor,
      backgroundColor,
      type,
      onKeyUp,
      errorColor,
      borderColor,
      borderRadius,
      border,
      margin,
      width,
      height,
      value,
      textAlign,
      icon,
      options
    } = this.props;
    const { open } = this.state;
    return (
      <div
        style={{
          display: "inline-block",
          textAlign: textAlign || "left",
          flexDirection: "column",
          width: width || "10vw",
          height: height || label ? "10vh" : "5vh",
          margin: margin || "auto"
        }}
        onFocus={() => this.setState({ open: true })}
        onBlur={() => this.setState({ open: false })}
      >
        <Label labelColor={labelColor} labelSize={labelSize}>
          {label}
        </Label>
        <div
          style={{
            display: "flex",

            border: border || "1px solid",
            borderColor: error
              ? errorColor || Theme.RED
              : borderColor || Theme.INPUT_OUTLINE_COLOR,
            borderRadius: borderRadius || "5px",
            backgroundColor: backgroundColor || Theme.INPUT_COLOR,
            flexDirection: "row",
            width: "100%",
            height: label ? "50%" : "100%"
          }}
        >
          {icon && (
            <FontAwesomeIcon
              icon={icon}
              style={{
                margin: "auto auto auto 1%",
                width: fontSize + ".5" || "1.3vw",
                height: fontSize + ".5" || "1.3vw",
                color: "grey"
              }}
            />
          )}
          <DropdownSelect
            {...this.props}
            error={!!error}
            placeholder={placeholder}
            onKeyUp={onKeyUp}
          />
          {!!error && <ErrorText>{error || "error"}</ErrorText>}
        </div>
        <Menu open={open} width={width} height={height}>
          <Text>Menu open</Text>
        </Menu>
      </div>
    );
  }
}
