import React, { Component } from "react";
import styled, { keyframes, css } from "styled-components";
import Theme from "./Theme.js";
import { Loader } from "./";
import { Input, Row, Text } from "./";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Wrapper = styled.table`
  margin: auto;
  width: auto;
`;
const Header = styled.th`
  display: flex;
  width: ${props => props.width || "8vw"};
  min-height: 5vh;
  border: 0.05vw solid;
  border-color: ${props => props.borderColor || Theme.BLACK};
  background-color: ${props => props.backgroundColor || Theme.MAIN_COLOR};
  color: ${props => props.color || "white"};
`;

const Column = styled.td`
  width: ${props => props.width || "8vw"};
  border: 0.05vw solid;
  justify-content: center;
  vertical-align: center;
  word-wrap: break-word;
  border-color: ${props => props.borderColor || Theme.BLACK};
  background: none;
  color: ${props => props.color || "white"};
`;
const Page = styled.button`
  display: flex;
  border: 0.05vw solid grey;
  border-radius: 10%;
  box-shadow: 0 0 0.1vw black;
  background: ${props => (props.disabled ? "grey" : "white")};
  height: 1.5vw;
  min-width: 1.5vw;
  min-height: 25px;
  font-size: 1vw;
  font-weight: bold;
  margin: 0.5vw 0.05vw 0.5vw 0.05vw;
  outline: none;
  cursor: pointer;
  transition-duration: 0.1s;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  &:active {
    background: grey;
    box-shadow: 0 0 0.05vw black;
    transition: all 0.1s;
  }
`;

const TableRow = styled.tr`
  width: 100%;
  display: flex;
  background-color: ${props => props.backgroundColor || Theme.WHITE};
  margin: auto;
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;
export default class Table extends Component {
  getPages = () => {
    const { page, total, limit, onPage, onSelect } = this.props;
    const totalPages = Math.ceil(total / limit);
    const pageGroup = parseInt(page / 6);
    var pages = [
      <Page
        key={"arrow1"}
        name={1}
        onClick={() => onPage(page - 1)}
        disabled={1 === page}
      >
        {"<"}
      </Page>,
      <Page key={1} name={1} onClick={() => onPage(1)} disabled={1 === page}>
        <Text> 1</Text>
      </Page>
    ];

    //add last page if more than 1 page
    if (total > 1 && totalPages > 1)
      pages[totalPages] = (
        <Page
          name={totalPages}
          onClick={() => onPage(total / limit)}
          disabled={totalPages == page}
        >
          <Text>{totalPages}</Text>
        </Page>
      );
    //add in ... before page group
    if (pageGroup > 0)
      pages[1] = (
        <Page onClick={() => onPage(pageGroup * 6 - 1)} disabled={i == page}>
          <Text>...</Text>
        </Page>
      );

    //add in ... after page group
    if (totalPages > 6 && totalPages > pageGroup * 6 + 5)
      pages[pageGroup * 6 + 6] = (
        <Page
          name={7}
          onClick={e => onPage(pageGroup * 6 + 6)}
          disabled={i == page}
        >
          <Text>...</Text>
        </Page>
      );

    //iterate from first of series page to the next 5
    if (totalPages > 1)
      for (
        var i = pageGroup * 6;
        i < pageGroup * 6 + 6 && i < totalPages;
        i++
      ) {
        if (i > 1)
          pages[i] = (
            <Page
              key={i}
              name={i}
              onClick={e => onPage(e.currentTarget.name)}
              disabled={i == page}
            >
              <Text>{i}</Text>
            </Page>
          );
      }

    pages.push(
      <Page
        key={"arrow2"}
        name={1}
        onClick={() => onPage(page + 1)}
        disabled={totalPages === page}
      >
        {">"}
      </Page>
    );
    return pages;
  };

  render() {
    const {
      columns,
      data,
      page,
      pages,
      onPage,
      limit,
      onLimit,
      onSort,
      total,
      onSelect,
      loading
    } = this.props;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column"
        }}
      >
        <Wrapper>
          <tbody
            style={{
              flexDirection: "column"
            }}
          >
            <TableRow>
              {columns.map((column, index) => (
                <Header width={column.width} key={index + "header"}>
                  <Text size="1.2vw" color="white" margin="auto">
                    {column.label}
                  </Text>
                </Header>
              ))}
            </TableRow>
            {loading ? (
              <Loader color={Theme.ACCENT_COLOR} size={"3vw"} />
            ) : (
              data &&
              data.map((row, rowIndex) => (
                <TableRow onClick={() => onSelect(row.userID)}>
                  {columns.map((column, index) => (
                    <Column
                      width={column.width}
                      key={index + rowIndex + "column"}
                    >
                      {column.render(row)}
                    </Column>
                  ))}
                </TableRow>
              ))
            )}
          </tbody>
          <Row noWrap width="100%">
            <Input
              margin="auto 0px auto 0px"
              width="50px"
              fontSize="15px"
              value={limit}
              onChange={v => onLimit(parseInt(v.currentTarget.value))}
              options={[
                { label: "10", value: 10 },
                { label: "20", value: 20 },
                { label: "50", value: 50 },
                { label: "100", value: 100 }
              ]}
            />
            <Text margin="auto auto auto 5px">Per page</Text>
            <Text>{!data || data.length < 1 ? "No Results Found" : ""}</Text>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "auto 0 auto auto"
              }}
            >
              {this.getPages()}
            </div>
          </Row>
        </Wrapper>
      </div>
    );
  }
}
