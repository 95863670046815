import React, { Component } from "react";
import styled, { css, keyframes } from "styled-components";
import Theme from "../../globalComponents/Theme.js";
import axios from "axios";
import { Button, InfoBox, Input, Text } from "../../globalComponents";
import emailjs from "emailjs-com";
export default class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      name: "",
      email: "",
      message: "",
      address: "",
      nameError: null,
      emailError: null,
      messageError: null,
      addressError: null,
    };
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event) {
    if (
      this.wrapperRef &&
      !this.wrapperRef.current.contains(event.target) &&
      this.props.open
    ) {
      this.props.onClose();
    }
  }

  verify = () => {
    const {
      name,
      email,
      message,
      nameError,
      emailError,
      messageError,
      addressError,
    } = this.state;
    var error = false;
    if (!name) {
      error = true;
      this.setState({ nameError: "Required" });
    } else this.setState({ nameError: "" });
    if (!email) {
      error = true;
      this.setState({ emailError: "Required" });
    } else this.setState({ emailError: "" });
    if (!email) {
      error = true;
      this.setState({ addressError: "Required" });
    } else this.setState({ addressError: "" });
    if (!message) {
      error = true;
      this.setState({ messageError: "Required" });
    } else this.setState({ messageError: "" });
    return !error;
  };

  submit = () => {
    const { name, email, message, address } = this.state;
    if (this.verify()) {
      var params = {
        name,
        email,
        message,
        address,
      };
      axios
        .post(`${process.env.REACT_APP_API_URL}/site/contact`, {
          domain: "thehoneydoboys.com",
          company: process.env.REACT_APP_COMPANY_NAME,
          emailName: "contact",
          replyName: name,
          replyEmail: email,
          message: message,
        })
        .then((response) => {
          if (response.data.success) {
            this.setState({ success: true });
          } else
            this.setState({
              general:
                "Couldn't send email,try checking your name and email or calling instead",
            });
        })
        .catch((error) =>
          this.setState({
            general:
              "Couldn't send email, try checking your name and email or calling instead",
          })
        );
    }
  };
  render() {
    const { open, onClose, mobile } = this.props;
    const {
      success,
      nameError,
      emailError,
      addressError,
      messageError,
      error,
      address,
      email,
      name,
      message,
      general,
    } = this.state;
    return (
      <div
        style={{
          display: open ? "flex" : "none",
          backgroundColor: "rgba(0,0,0,.7)",
          position: "fixed",
          height: "100vh",
          width: "100vw",
          top: 0,
          zIndex: 99,
        }}
      >
        <div
          ref={this.wrapperRef}
          style={{
            position: "fixed",
            top: "10vh",
            right: mobile ? "5vw" : "25vw",
            zIndex: 99,
            boxShadow: "0 0 20vw solid black",
            width: mobile ? "90vw" : "50vw",
            flexDirection: "column",
            backgroundColor: "white",
            padding: "1%",
            borderRadius: "10px",
          }}
        >
          {success ? (
            <div>
              <Text textAlign="center">Email sent successfully! </Text>
              <Button width="90%" margin="10% 0 1% 0" onClick={onClose}>
                Exit
              </Button>
            </div>
          ) : (
            <div>
              <Text textAlign="center" size={mobile ? "150%" : "200%"}>
                Request Your Quote Today
              </Text>
              <Input
                label="Your Name"
                error={nameError}
                value={name}
                onChange={(value) => this.setState({ name: value })}
              />
              <Input
                label="Your Email"
                error={emailError}
                value={email}
                onChange={(value) => this.setState({ email: value })}
              />
              <Input
                label="Service Address"
                error={addressError}
                value={address}
                onChange={(value) => this.setState({ address: value })}
              />
              <Input
                multiline
                height="30vh"
                margin="1% auto 1% auto"
                label="Describe Your GIS Mapping Needs"
                error={messageError}
                value={message}
                onChange={(value) => this.setState({ message: value })}
              />
              <Button width="90%" margin="10% 5% 1% 5%" onClick={this.submit}>
                <Text size={mobile ? "150%" : "200%"} color="white">
                  {" "}
                  Send
                </Text>
              </Button>
              <Text textAlign="center"> {general}</Text>
            </div>
          )}
        </div>
      </div>
    );
  }
}
